$header-footer-height: 50px;

body {
  font-family: 'Noto Sans KR', sans-serif;
  background-color: #f2f8f9;
}

.header .logo {
  display: block;
  width: 146px;
  height: 50px;
}

#content-wrapper {
  width: 100%;
  min-height: 100vh;
  display:flex;
  flex-direction: column;
  align-items: center;

  .header .header-fluid, .footer .footer-fluid {
    height: $header-footer-height;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 20px;
  }

  .header {
    background-color: #f2f8f9;
    color: $base-font-color;

    .header-fluid {
      max-width: $contents-max-width;
      justify-content: space-between;

      .system-title {
        font-size: 25px;
        font-weight: bold;
      }
      .user-info {
        display: flex;
        align-items: center;
      }

      .logout {
        color: $base-font-color;

        &:hover {
          color: #0d6efd;
        }
      }
    }
  }
  .footer {
    background-color: $base-color;
    color: $base-font-color;
    .footer-fluid {
      max-width: $contents-max-width;
    }
  }

  .content {
    padding: 20px;
    min-height: calc(100vh - #{$header-footer-height * 2});
  }

  .content .contents-fluid {
      max-width: $contents-max-width;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group.required .form-label:after {
    font-family: 'Glyphicons Halflings';
    font-weight: normal;
    margin: 2px;
    content:"*";
    color:red;
    position: absolute;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.w-70 {
  width: 70%
}

.no-data {
  color: #808080;
  opacity: 0.5;
}

.invalid-feedback {
  display: flex;
  align-items: center;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.5;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: 0.5;
}
.form-control::-ms-input-placeholder { /* Microsoft Edge */
  opacity: 0.5;
}
