@import "./variables.scss";

@import "./layout.scss";

.react-datepicker-wrapper {
  width: initial;
}

.question-wrapper {
  min-height: 500px;
  border: solid 1px black;
  padding: 20px;

  .question {
    min-height: 400px;
  }
  .answers {

  }
  .btn-wrapper {
    text-align: right;
  }
}

.content-header {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.content-block {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}
.content-block.type-100 {
  background-color: #f3a70033;
}
.question-item.required .title:after {
  font-family: 'Glyphicons Halflings';
  font-weight: normal;
  margin: 2px;
  content:"*";
  color:red;
  position: absolute;
}

.questionImage {
  display: flex;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.question-type1-item {
  .answers {
    display: flex;
    flex-wrap: wrap;

    .image-wrapper {
      /*display: flex;
      justify-content: center;
      align-items: center;*/
      height: 210px;
    }
    .image-wrapper .answer-img.active {
      border: solid 2px #f8b739;
      border-radius: 10px;
    }
    .answer-img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3px;
      /*width: 260px;*/
      height: 210px;
      box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);

      img {
        /*width: auto;
        height: 195px;*/
        margin: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.answer-table {
  /*display: table;*/
  width: 100%;
  overflow-x : auto;

  >.row {
    display: table-row;

    >.cell {
      display: table-cell;
      padding: 0px 20px;
      height: 50px;
      width: auto;
    }
  }
}

.answer-table-middle {
  vertical-align: middle;
}